import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfoContent from "./InfoContent";

const InfoModal = (props) => {
  const { show, onToggle } = props;
  const toggle = () => onToggle();
  return (
    <Modal
      className="d-print-none"
      isOpen={show}
      toggle={toggle}
      style={{ backgroundColor: '#E7E6E6' }}
      size="xl"
    >
      <ModalHeader toggle={toggle}>
        <FontAwesomeIcon
          icon="info-circle"
          size="lg"
          title="Info"
          className="ml-5"
          fixedWidth={true}
        />
      </ModalHeader>
      <ModalBody className='mx-5'>
        <InfoContent></InfoContent>
      </ModalBody>
    </Modal>
  );
};

export default InfoModal;
