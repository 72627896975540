import React from "react";
import {Router, Switch} from "react-router-dom";
import {Container} from "reactstrap";

import PrivateRoute from "./components/PrivateRoute";
import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Home from "./views/Home";
import {useAuth0} from "./react-auth0-spa";
import history from "./utils/history";

// styles
import "./App.scss";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";

initFontAwesome();

const App = () => {
  const {loading, isAuthenticated, loginWithRedirect} = useAuth0();

  React.useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }

    (async () => {
      await loginWithRedirect({appState: {targetUrl: '/'}});
    })();
  }, [loading, isAuthenticated, loginWithRedirect]);

  if (loading || !isAuthenticated) {
    return <Container className="print-page mt-4 text-center h-100"><Loading/></Container>;
  }

  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
        <NavBar/>
        <Switch>
          <PrivateRoute path="/" component={Home}/>
        </Switch>
      </div>
    </Router>
  );
};

export default App;
