import {useEffect, useRef, useState} from "react";
import {useAuth0} from "../react-auth0-spa";
import axios from "axios";

const ApiClient = (url, method = 'GET', body = null, requestOptions = {}) => {
    const getTokenSilently = useAuth0().getTokenSilently;
    const token = useRef(null);
    const [hasError, setErrors] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [response, setResponse] = useState(null);

    useEffect(() => {
        async function fetchData() {
            if (url === null || isLoading === true) {
                return;
            }
            setIsLoading(true);
            if (token.current == null) {
                token.current = await getTokenSilently();
            }
            const options = {
                method: method,
                timeout: 60000,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token.current}`
                },
                ...requestOptions
            };

            if (body !== null) {
                options.data = JSON.stringify(body);
            }

            axios.request(url, options)
                .then(res => {
                    console.debug(url, res);
                    setResponse(res.data);
                    setErrors(false);
                    setIsLoading(false);
                })
                .catch(err => setErrors(err));
        }

        fetchData();
    }, [url, method, isLoading, body, requestOptions, getTokenSilently]);

    return {
        response,
        setResponse,
        hasError
    };
};
export default ApiClient;