import React from "react";
import loading from "../assets/loading.svg";

const Loading = (props) => {
  const className = props.className || 'spinner'
  return (
    <div className={className} >
      <img src={loading} alt="Loading" />
    </div >
  )
};

export default Loading;
