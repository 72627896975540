import {library} from "@fortawesome/fontawesome-svg-core";
import {faInfoCircle, faLink, faPowerOff, faPrint, faSpinner, faTimes, faUser, faExclamationTriangle, faBars} from "@fortawesome/free-solid-svg-icons";

function initFontAwesome() {
    library.add(faLink);
    library.add(faBars);
    library.add(faUser);
    library.add(faPowerOff);
    library.add(faInfoCircle);
    library.add(faPrint);
    library.add(faSpinner);
    library.add(faTimes);
    library.add(faExclamationTriangle);
}

export default initFontAwesome;
