import React from "react";
import {Button, Col, Container, Form, FormFeedback, FormGroup, Input, Label, Row} from 'reactstrap';


const InputForm = (props) => {
    const [textValue, setTextValue] = React.useState("");
    const [nameValue, setNameValue] = React.useState("");
    const [gender, setGender] = React.useState("male");
    let {minWords, inputError, onAnalyseClick, onTextChange, onNameChange, onDiscProfileClick, isValid, requestPending} = props;
    const currentWordCount = textValue.match(/\b[-?(\w+)?]+\b/gi) || [];

    return (
        <Container className="flex-grow-1 mt-4 d-print-none">
            <Form className="d-print-none" onSubmit={e => e.preventDefault()}>
                <Row>
                    <Col md="12">
                        <FormGroup>
                            <Label for="inputName">Name des Kandidaten:</Label>
                            <Input type="text"
                                   value={nameValue}
                                   onChange={(event) => {
                                       onNameChange(event.target.value);
                                       setNameValue(event.target.value);
                                   }}
                                   name="text"
                                   id="inputName"
                                   autoComplete={"off"}
                                   maxLength="60"
                                   valid={isValid}
                                   invalid={inputError}/>
                        </FormGroup>
                    </Col>
                    <Col md="12">
                        <FormGroup>
                            <Label className="w-100" for="gender">Geschlecht des Kandidaten:</Label>
                            <FormGroup check inline>
                                <Label className="hand" check>
                                    <Input onChange={event => setGender(event.target.value)} type="radio"
                                           name="gender" value="male" checked={gender === 'male'}/>
                                    männlich
                                </Label>
                            </FormGroup>
                            <FormGroup check inline>
                                <Label className="hand" check>
                                    <Input onChange={event => setGender(event.target.value)} type="radio"
                                           name="gender" value="female" checked={gender === 'female'}/>
                                    weiblich
                                </Label>
                            </FormGroup>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="inputText">Geben Sie hier den Text des Kandidaten ein:</Label>
                            <Input type="textarea"
                                   value={textValue}
                                   onChange={(event) => {
                                       onTextChange(event.target.value);
                                       setTextValue(event.target.value);
                                   }}
                                   name="text"
                                   id="inputText"
                                   rows="10"
                                   valid={isValid}
                                   invalid={inputError}/>
                            <span className="d-block float-right clearfix my-2">
                                Wörter: {currentWordCount.length}/{minWords}
                              </span>
                            <FormFeedback valid={!inputError && !isValid}>Bitte geben Sie mindestens {minWords} Wörter
                                ein.</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="6" sm="6">
                        <Button disabled={requestPending} className="submit-button" color="primary" onClick={() => onAnalyseClick(textValue, nameValue)}>Faktoren analysieren</Button>
                    </Col>
                    <Col md="6" sm="6" className="d-flex justify-content-end">
                        <Button disabled={requestPending} className="submit-button" color="primary"
                                onClick={() => onDiscProfileClick(textValue, nameValue, gender)}>DISC Profil</Button>
                    </Col>
                </Row>
            </Form>
        </Container>
    );
}

InputForm.defaultProps = {
    minWords: 50,
    inputError: false,
    isValid: false,
    onNameChange: () => {
    },
    onTextChange: () => {
    },
    onAnalyseClick: () => {
    },
    onDiscProfileClick: () => {
    }
}

export default InputForm;