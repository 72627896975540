import React, { useEffect, useRef, useState } from "react";
import ApiClient from "../services/ApiClient";

import InputForm from "../components/InputForm";
import ResultPage from "../components/ResultPage";
import { Alert } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth0 } from "../react-auth0-spa";
import { useThemeContext } from "../theme/ThemeSelector";
import _ from 'lodash'

const submitInNewTab = (url, params, target = '_blank') => {
  const form = document.createElement("form");
  form.setAttribute("method", "post");
  form.setAttribute("action", url);
  form.setAttribute("target", target);
  for (const [field, value] of Object.entries(params)) {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = field;
    input.value = value;
    form.appendChild(input);
  }
  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
}

const Home = () => {
  const getTokenSilently = useAuth0().getTokenSilently;
  const themeContext = useThemeContext();
  const [scaleValues, setScaleValues] = useState({});
  const [inputError, setInputError] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [candidateName, setCandidateName] = useState("");
  const analysisApi = ApiWrapper();
  const discProfileV1Api = ApiWrapper('POST');
  const discProfileV2Api = ApiWrapper('POST');
  const divRef = useRef(null);
  const minWords = parseInt(process.env.REACT_APP_REQUIRED_WORD_COUNT || 50, 10);

  const checkLength = (textValue) =>
    (textValue.match(/\b[-?(\w+)]+\b/gi) || []).length > minWords;

  useEffect(() => {
    if (analysisApi.hasError) {
      analysisApi.setIsLoading(false);
      window.scrollTo(0, 0);
    }
    if (analysisApi.response === null) {
      return;
    }
    analysisApi.reset();
    if (analysisApi.response.scales && analysisApi.response.discValues) {
      analysisApi.setIsLoading(false);
      setScaleValues(analysisApi.response.scales);
    }
  }, [analysisApi.hasError, analysisApi.response]);

  const handlePdfExport = ({ hasError, setIsLoading, response, reset }) => {
    if (hasError) {
      setIsLoading(false);
      return;
    }
    if (response === null) {
      return;
    }
    reset();
    setIsLoading(false);
  };

  useEffect(() => {
    handlePdfExport(discProfileV1Api)
  }, [discProfileV1Api.hasError, discProfileV1Api.response, handlePdfExport]);
  useEffect(() => {
    handlePdfExport(discProfileV2Api)
  }, [discProfileV2Api.hasError, discProfileV2Api.response, handlePdfExport]);

  const onTextChange = (textValue) => {
    if (!checkLength(textValue)) {
      setInputError(false);
      setIsValid(false);
      return;
    }
    setIsValid(true);
  };

  const onAnalysisClick = (textValue, nameValue) => {
    if (!checkLength(textValue)) {
      setInputError(true);
      return;
    }
    if (analysisApi.isLoading) {
      return;
    }

    setInputError(false);
    analysisApi.setIsLoading(true);
    setScaleValues([]);
    divRef.current.scrollIntoView({ behavior: "smooth" });
    setCandidateName(nameValue);
    window.document.title = `${process.env.REACT_APP_TITLE}-${nameValue}`;
    analysisApi.setBody({ text: textValue });
    analysisApi.setRequestUrl("/api/personality");
  };

  const onDiscProfileClick = _.debounce(async (textValue, nameValue, genderValue) => {
    if (!checkLength(textValue)) {
      setInputError(true);
      return;
    }

    setInputError(false);
    setCandidateName(nameValue);

    const requestBody = {
      text: textValue,
      candidateName: nameValue,
      gender: genderValue,
      token: await getTokenSilently(),
      theme: themeContext.theme
    };

    submitInNewTab('/api/discprofiles', {
      ...requestBody,
      filename: `${process.env.REACT_APP_TITLE}-${nameValue}-V1.pdf`,
      version: "V1"
    });
    _.delay(() => submitInNewTab('/api/discprofiles', {
      ...requestBody,
      filename: `${process.env.REACT_APP_TITLE}-${nameValue}-V2.pdf`,
      version: "V2"
    }), 200);
  }, 200);

  return (
    <>
      {!analysisApi.hasError || (
        <Alert color="danger" className="d-print-none text-center">
          <FontAwesomeIcon icon="times" size="lg" color="#fff" />&nbsp;
          Fehler beim ermitteln der Persönlichkeitswerte, bitte versuchen Sie es
          später erneut.
        </Alert>
      )}
      {(discProfileV1Api.isLoading || discProfileV2Api.isLoading) && (
        <Alert color="info" className="d-print-none text-center">
          <FontAwesomeIcon icon="spinner" spin size="lg" />&nbsp;
          DISC Profil wird erstellt. Bitte haben Sie einen Moment Geduld.
        </Alert>
      )}
      <InputForm
        onAnalyseClick={onAnalysisClick}
        onTextChange={onTextChange}
        onDiscProfileClick={onDiscProfileClick}
        minWords={minWords}
        inputError={inputError}
        isValid={isValid}
        requestPending={(analysisApi.isLoading || discProfileV1Api.isLoading || discProfileV2Api.isLoading)}
      />
      <div ref={divRef}>
        <ResultPage {...{ scales: scaleValues, candidateName, isLoading: analysisApi.isLoading }} />
      </div>
    </>
  );
}

const ApiWrapper = (method = "POST", apiOptions = {}) => {
  const [requestUrl, setRequestUrl] = useState(null);
  const [body, setBody] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { response, hasError, setResponse } = ApiClient(requestUrl, method, body, apiOptions);

  return {
    requestUrl, setRequestUrl,
    body, setBody, isLoading,
    setIsLoading, response, hasError,
    reset: () => {
      setResponse(null)
      setRequestUrl(null);
      setBody(null);
    }
  }
}

export default Home;
