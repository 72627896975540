import React from 'react';

const FutureRecruitingTheme = React.lazy(() => import('./fundr/Theme'));
const GrepleTheme = React.lazy(() => import('./greple/Theme'));
const MyPegasusTheme = React.lazy(() => import('./mypegasus/Theme'));
const configuredThemes = {greple: <GrepleTheme/>, fundr: <FutureRecruitingTheme/>,mypegasus: <MyPegasusTheme />};

export const canSwitchTheme = () => {
  return process.env.REACT_APP_THEME_SWITCH === "true"
}

const initialTheme = (canSwitchTheme() && window.sessionStorage.getItem("active-theme")) || process.env.REACT_APP_THEME

export const ThemeContext = React.createContext(initialTheme)
export const useThemeContext = () => React.useContext(ThemeContext)
export const availableThemes = Object.keys(configuredThemes);

const ThemeSelector = ({children}) => {
  const [theme, setTheme] = React.useState(initialTheme)
  React.useEffect(()=>{
    window.sessionStorage.setItem("active-theme", theme);
    theme !== initialTheme && window.location.reload();
  },[theme])
  return (
    <ThemeContext.Provider value={{theme, setTheme}}>
        <React.Suspense fallback={<></>}>
          {configuredThemes[theme]}
        </React.Suspense>
        {children}
    </ThemeContext.Provider>
  )
}

export default ThemeSelector